import Layout from '../../../components/layout';
import React, { ReactElement, ReactNode } from 'react';
import TableVersion, { TableProps } from '../../../components/table_version';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
{
  postgres {
    allNovidades(condition: {verProduto: "2"}, orderBy: [VER_COMERCIAL_DESC, VER_RELEASE_DESC, IVE_BUILD_DESC, IVE_ITEM_DESC]) {
      nodes {
        iveNovidade
        iveBuild
        iveData
        iveDescricao
        iveItem
        iveModulo
        verComercial
        verRelease
        verProduto
        verLiberacao
        verVersao
      }
    }
  }
}
`

export default function Novidades(): ReactElement {
  const { postgres: { allNovidades: { nodes: data }
  } }: { postgres: { allNovidades: { nodes: TableProps[] } } } = useStaticQuery(query)

  const tables = new Map<string, TableProps[]>()

  data.forEach((row) => {
    tables.set(row.verVersao, tables.get(row.verVersao) ?? [])
    tables.get(row.verVersao)?.push(row)
  })

  const html: ReactNode[] = []

  for (const [version, rows] of tables) {
    html.push((
      <TableVersion
        key={version}
        rows={rows}
      />
    ))
  }

  return (
    <Layout title="Novidades MRE">
      <main className="u-nav-clear">
        <h1 className="title">Novidades</h1>
        <h2 className="subtitle">Veja o que de novo o MRE tem a oferecer</h2>
        {html}
      </main>
    </Layout>
  )
}
