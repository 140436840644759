import * as s from './styles/table_version.module.scss';
import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation } from '@reach/router';

interface Props {
  rows: TableProps[]
}

export interface TableProps {
  iveBuild: string
  iveData: string
  iveDescricao: string
  iveItem: string
  iveModulo: string
  iveNovidade: string
  verComercial: string
  verLiberacao: string
  verProduto: string
  verRelease: string
  verVersao: string
}

interface VersionProps {
  from: string
  to: string
}

export default function TableVersion({ rows }: Props): ReactElement {
  const version = `${rows[0].verComercial.padStart(2, '0')}.${rows[0].verRelease.padStart(2, '0')}`
  const location = useLocation()

  const rowRef = useRef<HTMLTableRowElement>(null)

  const [paramVersions, setParamVersions] = useState<VersionProps>({
    from: '00.00.00',
    to: '00.00.00',
  })

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setParamVersions({
      from: params.get('from')?.split('.').map(v => v.padStart(2, '0')).join('.') ?? paramVersions.from,
      to: params.get('to')?.split('.').map(v => v.padStart(2, '0')).join('.') ?? paramVersions.to,
    })
  }, [location])

  const getFullVersion = (build: string): string =>
    `${version}.${build.padStart(2, '0')}`

  const highlight = (build: string): boolean => {
    const fullVersion = getFullVersion(build)
    return paramVersions.from <= fullVersion && fullVersion <= paramVersions.to
  }

  let disable = false
  const getRef = (build: string): typeof rowRef | undefined => {
    const fullVersion = getFullVersion(build)
    if ((paramVersions.to === fullVersion) && !disable) {
      disable = true
      setTimeout(() => {
        const navbarOffset = (document.getElementById('navbar')?.offsetHeight ?? 0) + 10
        const elementPosition = rowRef.current?.getBoundingClientRect().top ?? 0;
        const offsetPosition = elementPosition - navbarOffset;
        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth"
        });
      }, 500)
      return rowRef
    }
  }

  return (
    <div>
      <div className={s.Separator}>
        <p>
          {`${version} `}
        </p>
        <p className={s.Separator_date}>
          {new Date(rows[0].verLiberacao).toLocaleDateString()}
        </p>
      </div>
      <table className={s.Table}>
        <tbody>
          {rows.map((row, index) => (
            <div
              key={`row${row.verVersao}${index}`}
              className={`${s.Row} ${highlight(row.iveBuild) ? s.Row___highlight : ''}`}
              ref={getRef(row.iveBuild)}
            >
              <div className={`${s.Row_mono} ${row.iveNovidade === 'S' ? s.Row_mono___adicao : s.Row_mono___ajuste}`}>
                {row.iveNovidade === 'S' ? 'Adição' : 'Ajuste'}
              </div>
              <div className={s.Row_mono}>
                {row.iveModulo}
              </div>
              <div className={s.Row_wrap}>
                {row.iveDescricao}
              </div>
              <div className={s.Row_mono}>
                {new Date(row.iveData).toLocaleDateString()}
              </div>
              <div className={s.Row_mono}>
                {row.iveBuild}
              </div>
            </div>
            // <tr
            //   key={`row${row.verVersao}${index}`}
            //   className={highlight(row.iveBuild) ? s.Table___highlight : ''}
            //   ref={getRef(row.iveBuild)}
            // >
            //   <td className={`${s.Table_mono} ${row.iveNovidade === 'S' ? s.Table_mono___adicao : s.Table_mono___ajuste}`}>
            //     {row.iveNovidade === 'S' ? 'Adição' : 'Ajuste'}
            //   </td>
            //   <td className={s.Table_mono}>{row.iveModulo}</td>
            //   <td className={s.Table_wrap}>{row.iveDescricao}</td>
            //   <td className={s.Table_mono}>{new Date(row.iveData).toLocaleDateString()}</td>
            //   <td className={s.Table_mono}>{row.iveBuild}</td>
            // </tr>
          ))}
        </tbody>
      </table>
    </div >
  )
}
