// extracted by mini-css-extract-plugin
export var Anchor = "table_version-module--Anchor--193gm";
export var Separator = "table_version-module--Separator--2t2KT";
export var Separator_date = "table_version-module--Separator_date--13sl5";
export var Table = "table_version-module--Table--2ffAv";
export var Table_wrap = "table_version-module--Table_wrap--1_D20";
export var Table_mono = "table_version-module--Table_mono--2kWL6";
export var Table_mono___ajuste = "table_version-module--Table_mono___ajuste--30i2D";
export var Table_mono___adicao = "table_version-module--Table_mono___adicao--a3M3L";
export var Table___highlight = "table_version-module--Table___highlight--2eQdt";
export var Row = "table_version-module--Row--1wW2f";
export var Row___highlight = "table_version-module--Row___highlight--pDgdG";
export var Row_mono = "table_version-module--Row_mono--3jGT_";
export var Row_mono___ajuste = "table_version-module--Row_mono___ajuste--2Vbkz";
export var Row_mono___adicao = "table_version-module--Row_mono___adicao--2SPKE";
export var Row_wrap = "table_version-module--Row_wrap--2B0Qw";
export var bob = "table_version-module--bob--2ykaO";